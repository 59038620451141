import { RoleFeatureTuple } from 'src/app/shared/services/auth.service';

export type AuthMatrix = Record<string, readonly RoleFeatureTuple[]>;

export const AUTH_MATRIX = {
  '': [{ role: '*', featureType: '*' }],
  agb: [{ role: '*', featureType: '*' }],
  legal: [{ role: '*', featureType: '*' }],
  'accept-agb': [{ role: '*', featureType: '*' }],
  dashboard: [{ role: '*', featureType: '*' }],
  dashboardv3: [{ role: '*', featureType: '*' }],
  welcome: [{ role: '*', featureType: '*' }],
  admin: [{ role: '*', featureType: '*' }],
  '**': [{ role: '*', featureType: '*' }],
  stammdaten: [{ role: '*', featureType: '*' }],
  behalter: [{ role: 'WASTE_PRODUCER', featureType: 'DWRM' }],
  abfallarten: [{ role: 'WASTE_PRODUCER', featureType: 'DWRM' }],
  entsorgungspartner: [{ role: 'WASTE_PRODUCER', featureType: 'DWRM' }],
  sammelstellen: [{ role: 'WASTE_PRODUCER', featureType: 'DWRM' }],
  awk: [{ role: '*', featureType: 'AWK' }],
  neu: [{ role: '*', featureType: 'AWK' }],
  ':id': [{ role: '*', featureType: 'AWK' }],
  'awk/:id': [{ role: '*', featureType: 'AWK' }],
  awkListe: [{ role: '*', featureType: 'AWK' }],
  'awk-wrapper': [{ role: '*', featureType: 'AWK' }],
  auftrage: [{ role: 'WASTE_PRODUCER', featureType: 'DWRM' }],
  auftragsverwaltung: [
    { role: 'WASTE_DISPOSAL', featureType: 'DWRM' },
    { role: 'WASTE_COLLECTOR', featureType: 'DWRM' },
  ],
  'aez/dokumente': [{ role: 'WASTE_PRODUCER', featureType: 'DWRM' }],
  'enb/dokumente': [
    { role: 'WASTE_DISPOSAL', featureType: 'DWRM' },
    { role: 'WASTE_COLLECTOR', featureType: 'DWRM' },
  ],
  'neuer-entsorgungspartner': [
    { role: 'WASTE_PRODUCER', featureType: 'MATCHMAKING' },
    { role: 'WASTE_PRODUCER', featureType: 'DWRM' },
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'DWRM' },
  ],
  'neuer-pickup': [{ role: '*', featureType: 'DWRM' }],
  'neue-entsorgungsanfrage': [
    { role: 'WASTE_PRODUCER', featureType: 'MATCHMAKING' },
  ],
  'erzeuger-anfragen': [{ role: 'WASTE_PRODUCER', featureType: 'MATCHMAKING' }],
  'erzeuger-angebote': [{ role: 'WASTE_PRODUCER', featureType: 'MATCHMAKING' }],
  'entsorger-anfragen': [
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
  ],
  'entsorger-angebote': [
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
  ],
  'neues-angebot': [
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
  ],
  'vertraegeDetails/:id': [
    { role: '*', featureType: 'MATCHMAKING' },
    { role: '*', featureType: 'DWRM' },
  ],
  vertraege: [
    { role: '*', featureType: 'MATCHMAKING' },
    { role: 'WASTE_PRODUCER', featureType: 'DWRM' },
  ],
  'vertraege/entwuerfe': [
    { role: '*', featureType: 'MATCHMAKING' },
    { role: 'WASTE_PRODUCER', featureType: 'DWRM' },
    { role: 'WASTE_DISPOSAL', featureType: 'DWRM' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_COLLECTOR', featureType: 'DWRM' },
  ],
  'erzeuger-vertraege': [
    { role: 'WASTE_PRODUCER', featureType: 'MATCHMAKING' },
    { role: 'WASTE_PRODUCER', featureType: 'DWRM' },
  ],
  'erzeuger-ausstehende-vertraege': [
    { role: 'WASTE_PRODUCER', featureType: 'MATCHMAKING' },
    { role: 'WASTE_PRODUCER', featureType: 'DWRM' },
  ],
  'entsorger-vertraege': [
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'DWRM' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_COLLECTOR', featureType: 'DWRM' },
  ],
  'entsorger-ausstehende-vertraege': [
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'DWRM' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_COLLECTOR', featureType: 'DWRM' },
  ],
  vertragsbedingungen: [{ role: '*', featureType: '*' }],
  betrieb: [
    { role: '*', featureType: '*' },
    { role: 'WASTE_PRODUCER', featureType: 'DWRM' },
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
  ],
  anlagen: [
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
  ],
  stoffstroeme: [
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
  ],
  zertifikate: [
    { role: 'WASTE_COLLECTOR', featureType: 'MATCHMAKING' },
    { role: 'WASTE_DISPOSAL', featureType: 'MATCHMAKING' },
  ],
  abfallstrome: [
    {
      role: 'WASTE_PRODUCER',
      featureType: 'DWRM',
    },
  ],
  'vertragsbedingungen/unternehmen/:companyId': [
    { role: '*', featureType: 'MATCHMAKING' },
  ],
  kundenschutzvereinbarung: [{ role: '*', featureType: 'MATCHMAKING' }],
  dashboardv2: [{ role: '*', featureType: '*' }],
} as const satisfies AuthMatrix;
